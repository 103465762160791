import { api } from "../services/api";

export function setAuthorizationHeader(token: string | null) {
  return api.defaults.headers.common.Authorization = token;
}

export function deleteAuthorizationHeader() {
  delete api.defaults.headers.common.Authorization;
}

export function setAuthorizationToken(
  accessToken: string,
  typeToken: string = 'Bearer'
): void {
  if (!accessToken) {
    localStorage.removeItem('@WhatsCodex:token');
    return deleteAuthorizationHeader();
  }

  localStorage.setItem('@WhatsCodex:token', accessToken);
  setAuthorizationHeader(`${typeToken} ${accessToken}`);
}

export function getAuthorizationToken(
  typeToken: string = 'Bearer'
): string | null {
  const token = localStorage.getItem('@WhatsCodex:token');

  if (token) {
    setAuthorizationHeader(`${typeToken} ${token}`);
  } else {
    deleteAuthorizationToken();
  }

  return token;
}

export function deleteAuthorizationToken() {
  localStorage.removeItem('@WhatsCodex:token');
}

export function saveTokenAdmin(): void {
  let tokenAdmin = getTokenAdmin();
  if (tokenAdmin)
    return
  tokenAdmin = getAuthorizationToken();
  localStorage.setItem('@WhatsCodex:tokenAdmin', tokenAdmin || '');
}

export function getTokenAdmin(): string | null {
  return localStorage.getItem('@WhatsCodex:tokenAdmin');
}

export function deleteTokenAdmin(): void {
  localStorage.removeItem('@WhatsCodex:tokenAdmin');
}

export function changeTokenUser(token: string) {
  saveTokenAdmin();
  setAuthorizationToken(token);
  window.location.reload();
}

export function returnTokenAdmin() {
  const tokenAdmin = getTokenAdmin();
  if (tokenAdmin)
    setAuthorizationToken(tokenAdmin);
  deleteTokenAdmin();
  window.location.reload();
}