import { TagProps } from "./types";
import { Container } from './styles';

export function TagView({ children, backgroundColor, color }: TagProps) {
  return (
    <Container backgroundColor={backgroundColor} color={color}>
      {children}
    </Container>
  );
}
