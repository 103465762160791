import { useAuth } from "hooks/useAuth";
import { Link } from "react-router-dom";
import { AdminList } from "types/User";

export function OptionManageUsers() {
    const { user } = useAuth();

    if (user == null || !AdminList.includes(user.name)) {
        return (
            <div></div>
        );
    } else {
        return (
            <tr>
                <td>Gerenciar usuários</td>
                <td><Link to={"/users"} >Acessar</Link></td>
            </tr>
        );
    }
}