import styled, { css } from "styled-components";
import { ImageProps } from "./types";
import { rgba } from "polished";

export const Container = styled.div`
  border-radius: 0.625rem;
  overflow: hidden;
`;

export const Image = styled.img<ImageProps>`
  width: 100%;
  cursor: pointer;

  ${props => !props.hasCaption && css`
    height: 100%;
  `}
`;

export const Caption = styled.p`
  margin: 0.3125rem;
  padding: 0 0.4375rem 0.5rem 0.5625rem;
  line-height: 1.3;
  white-space: pre-wrap;
  word-break: break-word;
`;

export const StickerLabel = styled.span`
  display: block;
  position: absolute;
  top: 0.625rem;
  left: 0.625rem;
  border-radius: 0.25rem;
  font-size: 0.75rem;
  padding: 0.3125rem 0.625rem;
  background: ${props => rgba(props.theme.colors.defaultMessage, 0.5)};
`;
