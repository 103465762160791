import Modal from 'react-modal';
import { Input } from 'components/Form/Input';
import { Button } from 'styles/Button';
import { InputGroup } from 'styles/InputGroup';
import closeImg from '../../assets/close.svg';
import { Form } from 'styles/Form';
import { ContactModalProps } from './types';
import { yupResolver } from '@hookform/resolvers/yup';
import { Control, UseFormSetError, useForm } from 'react-hook-form';
import { contactValidator } from './validation';
import { FieldValues } from 'react-hook-form';
import { useEffect } from 'react';
import { ModalTitle } from 'styles/Modal';
import { useEditContact } from 'hooks/mutations/useEditContact';
import { Contact, EditContactData } from 'types/Contact';
import { api } from 'services/api';
import { isEmpty } from 'utils/Misc';
import { handleRequestError } from 'utils/Request';
import { formatPhoneNumberIntl } from 'react-phone-number-input';
import { PhoneInput } from 'components/Form/PhoneInput';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { useTheme } from 'styled-components';

const validationSchema = contactValidator();

export function EditContactModal({ isOpen, onRequestClose, contactToEdit }: ContactModalProps) {
  const { handleSubmit, setValue, control, setError, reset, formState: { isSubmitting } } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      name: '',
      phone_number: '',
      user_company_id: '',
    },
  });

  const theme = useTheme();

  useEffect(() => {
    setValue('name', contactToEdit.name);
    setValue('phone_number', contactToEdit.phone_number);
    setValue('user_company_id', contactToEdit.user_company_id || '');
  }, [isOpen, setValue, contactToEdit]);

  function handleRequestClose() {
    reset();
    onRequestClose();
  }

  const editContact = useEditContact(setError as UseFormSetError<FieldValues>);

  const ReactSwal = withReactContent(Swal)

  async function shouldEditContact(contact: Contact) {
    return await ReactSwal.fire({
      title: 'Um contato semelhante foi encontrado!',
      html: <span>O contato <b>{contact.name} ({formatPhoneNumberIntl(`+${contact.phone_number}`)})</b> é semelhante à este contato. Deseja editar mesmo assim?</span>,
      icon: 'warning',
      showCancelButton: true,
      showConfirmButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Forçar edição',
      confirmButtonColor: theme.colors.danger,
      reverseButtons: true,
    });
  }

  async function handleEditContact({ name, phone_number, user_company_id }: EditContactData) {
    let willEdit;

    try {
      const response = await api.post('/check/contacts', { name, phone_number, user_company_id });

      if (!isEmpty(response.data)) {
        const existingContact = response.data;
        willEdit = await shouldEditContact(existingContact);
      }
    } catch (error) {
      handleRequestError(error);
    }

    if (willEdit && !willEdit?.isConfirmed) return;

    const editedUserCompanyId = !user_company_id ? null : user_company_id;

    await editContact.mutateAsync({ contactToEdit, data: { name, phone_number, user_company_id: editedUserCompanyId } });
    handleRequestClose();
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleRequestClose}
      overlayClassName="react-modal-overlay"
      className="react-modal-content"
    >
      <button
        type="button"
        onClick={handleRequestClose}
        className="react-modal-close"
      >
        <img src={closeImg} alt="Fechar modal" loading="lazy" />
      </button>

      <div>
        <ModalTitle>Editar contato</ModalTitle>

        <Form onSubmit={handleSubmit(handleEditContact)}>
          <InputGroup>
            <Input
              control={control as unknown as Control<FieldValues, object>}
              type="text"
              name="name"
              id="name"
              label="Nome"
              placeholder="Contato"
              fullwidth
            />
          </InputGroup>
          <InputGroup>
            <PhoneInput
              control={control as unknown as Control<FieldValues, object>}
              name="phone_number"
              id="phone_number"
              label="Telefone"
              placeholder="Número de telefone"
              padding=".8rem 5rem .8rem 1.5rem"
              fullwidth
              options={{
                defaultCountry: 'BR',
                countryCallingCodeEditable: true,
                withCountryCallingCode: true,
              }}
            />
          </InputGroup>
          <InputGroup>
            <Input
              control={control as unknown as Control<FieldValues, object>}
              type="text"
              name="user_company_id"
              id="user_company_id"
              label="UUID do usuário no sistema"
              placeholder="12345678"
              padding=".8rem 5rem .8rem 1.5rem"
              fullwidth
            />
          </InputGroup>
          <InputGroup>
            <Button type="submit" fullwidth disabled={isSubmitting}>
              {isSubmitting ? 'Carregando...' : 'Editar contato'}
            </Button>
          </InputGroup>
        </Form>
      </div>
    </Modal>
  );
}
