import { useState } from "react";
import { Dropdown } from "components/Dropdown";
import { DropdownListItem } from "components/Dropdown/styles";

import { ArrowRightCircle, Check, ChevronDown, Edit, MessageCircle, Trash } from "react-feather";

import { Container, Content, Options, MessageOptions, ContentWrapper, QuotedMessageContainer, Info, Seen } from "./styles";

import { MessageProps } from "./types";
import { toast } from 'react-toastify';
import { ForwardMessageModal } from 'components/ForwardMessageModal';
import { strToTime } from 'utils/Datetime';
import { useTheme } from 'styled-components';
import { QuotedMessage } from 'styles/QuotedMessage';
import { handleRequestError } from 'utils/Request';
import { api } from 'services/api';
import { ChatMessage } from 'types/ChatMessage';
import { TextMessage } from 'components/TextMessage';
import { ImageMessage } from 'components/ImageMessage';
import { VideoMessage } from 'components/VideoMessage';
import { AudioMessage } from 'components/AudioMessage';
import { DocumentMessage } from 'components/DocumentMessage';
import { Annotation } from 'components/Annotation';
import { TextQuote } from 'components/TextQuote';
import { ImageQuote } from 'components/ImageQuote';
import { VideoQuote } from 'components/VideoQuote';
import { AudioQuote } from 'components/AudioQuote';
import { DocumentQuote } from 'components/DocumentQuote';
import { useQueryClient } from 'react-query';
import { useCount } from 'hooks/utils/useCount';
import { CallLogMessage } from 'components/CallLogMessage';
import { CallLogQuote } from 'components/CallLogQuote';
import { useMessages } from 'hooks/useMessages';
import { ContactMessage } from 'components/ContactMessage';
import { ContactQuote } from 'components/ContactQuote';
import Swal from 'sweetalert2';
import { ReactionMessage } from "components/ReactionMessage";
import { DeletedMessage } from "components/DeletedMessage";

export function Message({ message, isSending }: MessageProps) {
  const theme = useTheme();

  const { setSelectedQuotedMessage } = useMessages();

  const queryClient = useQueryClient();
  const { revalidateCount } = useCount();

  const [isForwardMessageModalOpen, setIsForwardMessageModalOpen] = useState(false);

  function handleOpenForwardMessageModal() {
    setIsForwardMessageModalOpen(true);
  }

  function handleCloseForwardMessageModal() {
    setIsForwardMessageModalOpen(false);
  }

  async function handleMessageDeletion(messageId: string) {
    const willDelete = await Swal.fire({
      title: 'Excluir mensagem!',
      text: 'Tem certeza que deseja excluir esta mensagem?',
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      showConfirmButton: true,
      confirmButtonColor: theme.colors.danger,
      confirmButtonText: 'Excluir mensagem',
      reverseButtons: true,
    });

    if (!willDelete.isConfirmed) return false;

    try {
      await api.delete(`/messages/${messageId}`);
      toast.success('Mensagem excluida com sucesso!');
      queryClient.invalidateQueries('messages');
    } catch (error) {
      handleRequestError(error, 'Um erro ocorreu ao deletar esta mensagem. Por favor, tente novamente.');
    }
  }

  async function handleEditMessage(message: ChatMessage) {
    message.body = message.body.replace(/(\*.*\*)/, '');
    const { value: newMessage } = await Swal.fire({
      title: 'Editar mensagem',
      input: 'textarea',
      inputPlaceholder: 'Digite a nova mensagem',
      inputValue: message.body,
      inputAttributes: {
        autocapitalize: 'off',
      },
      heightAuto: true,
      color: theme.colors.text,
      showCancelButton: true,
      confirmButtonText: 'Editar',
      cancelButtonText: 'Cancelar',
      showLoaderOnConfirm: true,
      preConfirm: (newMessage) => {
        if (!newMessage) {
          Swal.showValidationMessage('Digite a nova mensagem');
        }
        return api.put(`/messages/${message.whatsapp_msg_id}`, { body: newMessage })
          .then(() => {
            toast.success('Mensagem editada com sucesso!');
            queryClient.invalidateQueries('messages');
          })
          .catch(error => {
            handleRequestError(error, 'Um erro ocorreu ao editar esta mensagem. Por favor, tente novamente.');
          });
      },
      allowOutsideClick: () => !Swal.isLoading(),
    });
  }

  async function handleMarkMessageAsUnread(message: ChatMessage) {
    try {
      await api.post(`/messages/${message.whatsapp_msg_id}/mark-as-unread`);
      toast.success('Mensagem marcada como não lida!');
    } catch (error) {
      handleRequestError(error, 'Falha ao marcar mensagem como não lida. Por favor, tente novamente!');
    }

    queryClient.invalidateQueries('messages');

    revalidateCount();
  }

  async function handleMarkMessageAsRead(message: ChatMessage) {
    try {
      await api.post(`/messages/${message.whatsapp_msg_id}/mark-as-read`);
      toast.success('Mensagem marcada como lida!');
    } catch (error) {
      handleRequestError(error, 'Falha ao marcar mensagem como lida. Por favor, tente novamente!');
    }

    queryClient.invalidateQueries('messages');

    revalidateCount();
  }

  function renderMessageContent(message: ChatMessage) {
    if (message.is_annotation) {
      return <Annotation annotation={message} />
    }

    switch (message.type) {
      case 'chat':
        return <TextMessage message={message} />;
      case 'image':
        return <ImageMessage message={message} />;
      case 'video':
        return <VideoMessage message={message} />;
      case 'ptt':
        return <AudioMessage message={message} />;
      case 'audio':
        return <AudioMessage message={message} isAudioFile />;
      case 'document':
        return <DocumentMessage message={message} />;
      case 'call_log':
        return <CallLogMessage />;
      case 'reaction':
        return <ReactionMessage message={message} />;
      case 'vcard':
      case 'multi_vcard':
        return <ContactMessage message={message} />;
      case 'deleted':
        return <DeletedMessage />;
      case 'sticker':
        return <ImageMessage message={message} isSticker />;
      default:
        return <TextMessage message={message} />;
    }
  }

  function renderQuoteContent(quote: ChatMessage) {
    switch (quote.type) {
      case 'chat':
        return <TextQuote quote={quote} />
      case 'image':
        return <ImageQuote quote={quote} />
      case 'video':
        return <VideoQuote quote={quote} />
      case 'ptt':
        return <AudioQuote quote={quote} />
      case 'audio':
        return <AudioQuote quote={quote} isAudioFile />
      case 'document':
        return <DocumentQuote quote={quote} />
      case 'call_log':
        return <CallLogQuote quote={quote} />
      case 'vcard':
      case 'multi_vcard':
        return <ContactQuote quote={quote} />
      case 'sticker':
        return <ImageQuote quote={quote} />
      default:
        return <TextQuote quote={quote} />
    }
  }

  function handleReplyToMessage(message: ChatMessage) {
    setSelectedQuotedMessage(message);
  }

  function willRenderOptions() {
    if (isSending) {
      return false;
    }

    if (message.is_annotation && !message.user_id) {
      return false;
    }

    return true;
  }

  return (
    <>
      <Container fromMe={message.from_me} id={message.id}>
        <Content
          fromMe={message.from_me}
          isUnread={!message.is_read}
          isSending={isSending}
          isAudioFile={message.type === 'audio'}
        >
          {willRenderOptions() && (
            <Options>
              <Dropdown trigger={
                <MessageOptions isHighlighted={['image', 'video'].includes(message.type)}>
                  <ChevronDown size="16" />
                </MessageOptions>
              } closeOnClickInside={true} pin={message.from_me ? 'right' : 'left'} mode="up">
                <ul>
                  <DropdownListItem onClick={() => handleReplyToMessage(message)}>
                    <MessageCircle color={theme.colors.text} size="16" />
                    <span>Responder</span>
                  </DropdownListItem>

                  {/*
                    <DropdownListItem onClick={handleOpenForwardMessageModal}>
                      <ArrowRightCircle color={theme.colors.text} size="16" />
                      <span>Encaminhar</span>
                    </DropdownListItem>
                  */}

                  {!message.from_me && (
                    message.is_read
                      ? (
                        <DropdownListItem onClick={() => handleMarkMessageAsUnread(message)}>
                          <Check color={theme.colors.text} size="16" />
                          <span>Marcar como não lida</span>
                        </DropdownListItem>
                      ) : (
                        <DropdownListItem onClick={() => handleMarkMessageAsRead(message)}>
                          <Check color={theme.colors.text} size="16" />
                          <span>Marcar como lida</span>
                        </DropdownListItem>
                      )
                  )}

                  {/* 
                    {message.from_me && (
                      <>
                        {message.type === 'chat' && (
                          <DropdownListItem onClick={() => handleEditMessage(message)}>
                            <Edit color={theme.colors.text} size="16" />
                            <span>Editar</span>
                          </DropdownListItem>
                        )}
                        <DropdownListItem onClick={() => handleMessageDeletion(message.whatsapp_msg_id)} danger>
                          <Trash color={theme.colors.danger} size="16" />
                          <span>Excluir</span>
                        </DropdownListItem>
                      </>
                    )}
                  */}
                </ul>
              </Dropdown>
            </Options>
          )}

          <ContentWrapper messageType={message.type} isMedia={['image', 'video', 'ptt', 'sticker'].includes(message.type)}>
            {message.quoted && (
              <QuotedMessageContainer>
                <QuotedMessage
                  to={message.quoted.id}
                  containerId="chat-container"
                  smooth={true}
                  duration={300}
                  fromMe={message.quoted.from_me}
                  sent
                >
                  {renderQuoteContent(message.quoted)}
                </QuotedMessage>
              </QuotedMessageContainer>
            )}
            {renderMessageContent(message)}
          </ContentWrapper>

          <Info isHighlighted={['image', 'video'].includes(message.type) && !message.caption && !message.is_forwarded}>
            {strToTime(message.time)}
            {message.from_me && (
              <Seen>
                <Check size="12" />
                {(message.ack === 'RECEIVED' || message.ack === 'READ') && <Check size="12" />}
              </Seen>
            )}
          </Info>
        </Content>
      </Container>

      {isForwardMessageModalOpen && <ForwardMessageModal
        isOpen={isForwardMessageModalOpen}
        onRequestClose={handleCloseForwardMessageModal}
        messageToForward={message}
      />}
    </>
  );
}
