import Modal from "react-modal";
import { ModalTitle } from "styles/Modal";
import closeImg from '../../assets/close.svg';
import { Control, FieldValues, SubmitHandler, UseFormSetError, useForm } from "react-hook-form";
import { User } from "types/User";
import { useEditUser } from "hooks/mutations/useEditUser";
import { useEffect } from "react";
import { Input } from "components/Form/Input";
import { InputGroup } from "styles/InputGroup";
import { Button } from "styles/Button";
import { Form } from "styles/Form";
import { Buttons } from "components/MessageForm/styles";
import { Category } from "types/Category";
import { Dropdown } from "components/Dropdown";
import { CategoryList } from "./components/CategoryList";

export function EditUserModal({ isOpen, onRequestClose, userToEdit }: any) {
    const { handleSubmit, setValue, setError, control, reset, formState: { isSubmitting } } = useForm<FieldValues>({
        defaultValues: {
            name: '',
            email: '',
            default_category_id: '',
        },
    });

    useEffect(() => {
        setValue('name', userToEdit.name);
        setValue('email', userToEdit.email);
        setValue('default_category_id', userToEdit.default_category_id);
    }, [setValue, userToEdit]);

    function handleRequestClose() {
        reset();
        onRequestClose();
    }

    const editUser = useEditUser(setError as UseFormSetError<FieldValues>);

    async function handleEditUser({ name, email, default_category_id }: User) {
        await editUser.mutateAsync({ userToEdit, data: { name, email, default_category_id } });
        handleRequestClose();
    }

    // Categories
    function handleChangeUserCategory(category: Category) {
        setValue('default_category_id', category.id);
    }

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={handleRequestClose}
            overlayClassName="react-modal-overlay"
            className="react-modal-content fixed-height"
        >
            <button
                type="button"
                onClick={handleRequestClose}
                className="react-modal-close"
            >
                <img src={closeImg} alt="Fechar modal" loading="lazy" />
            </button>

            <div>
                <ModalTitle>Editar {userToEdit.name ? `- ${userToEdit.name}` : 'usuário'}</ModalTitle>
                <Form onSubmit={handleSubmit(handleEditUser as SubmitHandler<FieldValues>)}>
                    <InputGroup className="form-group">
                        <Input
                            control={control as Control<FieldValues>}
                            type="text"
                            name="name"
                            id="name"
                            label="Nome"
                            maxLength={255}
                            fullwidth />
                    </InputGroup>
                    <InputGroup>
                        <Input
                            control={control as Control<FieldValues>}
                            type="text"
                            name="email"
                            id="email"
                            label="E-mail"
                            maxLength={255}
                            fullwidth />
                    </InputGroup>
                    <InputGroup>
                        <Dropdown
                            trigger={
                                <Button
                                    type="button"
                                    medium={!window.matchMedia("(max-width: 1366px)").matches}
                                    small={window.matchMedia("(max-width: 1366px)").matches}
                                    dropdown
                                >
                                    Setores
                                </Button>
                            }
                            closeOnClickInside={true}
                        >
                            <CategoryList
                                handleChangeUserCategory={handleChangeUserCategory}
                            />
                        </Dropdown>
                    </InputGroup>
                    <InputGroup>
                        <Buttons>
                            <Button type="submit" fullwidth disabled={isSubmitting}>{isSubmitting ? 'Carregando...' : 'Salvar'}</Button>
                            <Button type="button" onClick={onRequestClose}>Cancelar</Button>
                        </Buttons>
                    </InputGroup>
                </Form>
            </div>
        </Modal>
    );
}
