import Modal from "react-modal";
import { ModalTitle } from "styles/Modal";
import closeImg from '../../../../assets/close.svg';
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import { CreateUserData } from "types/User";
import { useEffect, useState } from "react";
import { Input } from "components/Form/Input";
import { InputGroup } from "styles/InputGroup";
import { Button } from "styles/Button";
import { Form } from "styles/Form";
import { Buttons } from "components/MessageForm/styles";
import { Category } from "types/Category";
import { Dropdown } from "components/Dropdown";
import { CategoryList } from "components/EditUserModal/components/CategoryList";
import { useCreateUser } from "hooks/mutations/useCreateUser";
import { useAuth } from "hooks/useAuth";


export function CreateUserModal({ isOpen, onRequestClose }: any) {
    const { handleSubmit, setValue, setError, control, reset, formState: { isSubmitting } } = useForm<FieldValues>({
        defaultValues: {
            name: '',
            email: '',
            default_category_id: '',
            password: '',
            password_confirmation: '',
        },
    });

    const [userToCreate] = useState<CreateUserData>({} as CreateUserData);
    const { user } = useAuth();

    useEffect(() => {
        setValue('name', userToCreate.name);
        setValue('email', userToCreate.email);
        setValue('password', userToCreate.password);
        setValue('password_confirmation', userToCreate.password_confirmation);
        setValue('default_category_id', userToCreate.default_category_id);
        setValue('company_id', user?.company_id);
    }, [setValue, userToCreate]);

    function handleRequestClose() {
        reset();
        onRequestClose();
    }

    const createUser = useCreateUser(setError);

    async function handleCreateUser({ name, email, password, password_confirmation, default_category_id, company_id }: CreateUserData) {
        await createUser.mutateAsync({ data: { name, email, password, password_confirmation, default_category_id, company_id } });
        handleRequestClose();
    }

    // Categories
    function handleChangeUserCategory(category: Category) {
        setValue('default_category_id', category.id);
    }

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={handleRequestClose}
            overlayClassName="react-modal-overlay"
            className="react-modal-content fixed-height"
        >
            <button
                type="button"
                onClick={handleRequestClose}
                className="react-modal-close"
            >
                <img src={closeImg} alt="Fechar modal" loading="lazy" />
            </button>

            <div>
                <ModalTitle>Criar usuário</ModalTitle>
                <Form onSubmit={handleSubmit(handleCreateUser as SubmitHandler<FieldValues>)}>
                    <InputGroup className="form-group">
                        <Input
                            control={control}
                            type="text"
                            name="name"
                            id="name"
                            label="Nome"
                            maxLength={255}
                            fullwidth />
                    </InputGroup>
                    <InputGroup>
                        <Input
                            control={control}
                            type="text"
                            name="email"
                            id="email"
                            label="E-mail"
                            maxLength={255}
                            fullwidth />
                    </InputGroup>
                    <InputGroup>
                        <Input
                            control={control}
                            type="password"
                            name="password"
                            id="password"
                            label="Senha"
                            maxLength={255}
                            fullwidth />
                    </InputGroup>
                    <InputGroup>
                        <Input
                            control={control}
                            type="password"
                            name="password_confirmation"
                            id="password_confirmation"
                            label="Confirmar senha"
                            maxLength={255}
                            fullwidth />
                    </InputGroup>
                    <InputGroup>
                        <Dropdown
                            trigger={
                                <Button
                                    type="button"
                                    medium={!window.matchMedia("(max-width: 1366px)").matches}
                                    small={window.matchMedia("(max-width: 1366px)").matches}
                                    dropdown
                                >
                                    Setores
                                </Button>
                            }
                            closeOnClickInside={true}
                        >
                            <CategoryList
                                handleChangeUserCategory={handleChangeUserCategory}
                            />
                        </Dropdown>
                    </InputGroup>
                    <InputGroup>
                        <Buttons>
                            <Button type="submit" fullwidth disabled={isSubmitting}>{isSubmitting ? 'Carregando...' : 'Criar'}</Button>
                            <Button type="button" onClick={onRequestClose}>Cancelar</Button>
                        </Buttons>
                    </InputGroup>
                </Form>
            </div>
        </Modal>
    );
}
