import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  background: ${props => props.theme.colors.background};
  border-top: 230px solid ${props => props.theme.colors.primary};
`;

export const ContentTable = styled.div`
  width: 100%;
  height: 97vh;
  overflow-y: scroll;
  background: ${props => props.theme.colors.background};
  padding-top: 5px;
`;

export const Content = styled.div`
  background: ${props => props.theme.colors.contentBackground};
  width: 100%;
  max-width: 115rem;
  margin: 0 auto;
  height: calc(100% - 4rem);
  position: fixed;
  left: 50%;
  top: 2rem;
  transform: translateX(-50%);
  box-shadow: 0px 4px 24px rgb(0 0 0 / 10%);
  display: grid;
  grid-template-columns: minmax(230px, 1fr) 4fr 2fr;

  @media only screen and (max-width: 1366px) {
    max-width: 90rem;
  }
`;
