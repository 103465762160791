import { CancelReply, Container } from "./styles";

import closeImg from '../../assets/close.svg';
import { QuotedMessage } from "styles/QuotedMessage";
import { ChatMessage } from "types/ChatMessage";
import { TextQuote } from "components/TextQuote";
import { ImageQuote } from "components/ImageQuote";
import { VideoQuote } from "components/VideoQuote";
import { AudioQuote } from "components/AudioQuote";
import { DocumentQuote } from "components/DocumentQuote";
import { CallLogQuote } from "components/CallLogQuote";
import { useMessages } from "hooks/useMessages";
import { useEffect } from "react";
import { ContactQuote } from "components/ContactQuote";

export function Quote() {
  const {
    selectedQuotedMessage,
    setSelectedQuotedMessage
  } = useMessages();

  function renderQuoteContent(quote: ChatMessage) {
    switch (quote.type) {
      case 'chat':
        return <TextQuote quote={quote} />
      case 'image':
        return <ImageQuote quote={quote} />
      case 'video':
        return <VideoQuote quote={quote} />
      case 'ptt':
        return <AudioQuote quote={quote} />
      case 'audio':
        return <AudioQuote quote={quote} isAudioFile />
      case 'document':
        return <DocumentQuote quote={quote} />
      case 'call_log':
        return <CallLogQuote quote={quote} />
      case 'vcard':
      case 'multi_vcard':
        return <ContactQuote quote={quote} />
      case 'sticker':
        return <ImageQuote quote={quote} />
      default:
        return <TextQuote quote={quote} />
    }
  }

  function handleCancelReply() {
    setSelectedQuotedMessage({} as ChatMessage);
  }

  useEffect(() => {
    function handleStopQuoting(e: KeyboardEvent) {
      if (e.key === 'Escape') {
        setSelectedQuotedMessage({} as ChatMessage);
      }
    }

    document.addEventListener('keydown', handleStopQuoting);

    return () => {
      document.removeEventListener('keydown', handleStopQuoting);
    }
  }, [setSelectedQuotedMessage]);

  return (
    <Container>
      <QuotedMessage
        to={selectedQuotedMessage.id}
        containerId="chat-container"
        smooth={true}
        duration={300}
        fromMe={selectedQuotedMessage.from_me}
      >
        {renderQuoteContent(selectedQuotedMessage)}
      </QuotedMessage>
      <CancelReply onClick={handleCancelReply}>
        <img src={closeImg} alt="Cancelar resposta" loading="lazy" />
      </CancelReply>
    </Container>
  );
}
