import { CreateUser } from "components/CreateUser";
import { Container, Content } from "./styles";
import { LoggedUser } from "components/LoggedUser";
import { Heeader } from "components/Sidenav/styles";
import { UsersTable } from "components/UsersTable";
import { useAuth } from "hooks/useAuth";
import { AdminList } from "types/User";

export function Users() {
  const { user } = useAuth();

  if (user == null || !AdminList.includes(user.name)) {
    return (
      <Container>
        <Content>
          <Heeader>
            <LoggedUser />
          </Heeader>
          <div style={{alignSelf: "center", justifySelf: "center"}}>
            <h2>Você não tem permissão para acessar essa página!</h2>
          </div>
        </Content>
      </Container>
    );
  }

  return (
    <Container>
      <Content>
        <Heeader>
          <LoggedUser />
          <CreateUser />
        </Heeader>
        <UsersTable endpoint="/users"></UsersTable>
      </Content>
    </Container>
  )
}
