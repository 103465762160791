import { useState } from 'react';
import { useUsers } from "hooks/fetch/useUsers";
import { LoadingSpinner } from "components/LoadingSpinner";
import { Loading } from "components/ContactsTable/styles";
import { User } from "types/User";
import { Container, UserListUl, UserListItem, DiscreetButton } from "./styles";
import { useAuth } from 'hooks/useAuth';
import { getTokenUser } from 'services/auth';
import { changeTokenUser, getTokenAdmin, returnTokenAdmin } from 'utils/Auth';

export function UserDropdown({ endpoint }: any) {
    const { loadMoreRef, query: { data, isLoading, isFetchingNextPage } } = useUsers(endpoint);
    const [isOpen, setIsOpen] = useState(false);
    const { user, logout } = useAuth();
    const userOriginal = getTokenAdmin();

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const changeUser = async (userSelected: User, user: User) => {
        if (userSelected.id === user.id) {
            return;
        }
        try {
            const { token } = await getTokenUser(userSelected.id);
            changeTokenUser(token);
        } catch (error) {
            console.log(error);
            console.warn('Erro ao trocar de usuário');
        }
        setIsOpen(false);
    };

    const UserList = ({ users, changeUser }: any) => {
        return (
            <Container>
                <UserListUl>
                    {users.map((userSelect: User) => (
                        <UserListItem key={userSelect.id} onClick={() => changeUser(userSelect, user)}>
                            {userSelect.name}
                        </UserListItem>
                    ))}
                </UserListUl>
            </Container>
        );
    };

    return (
        <Container>
            {isLoading && !isFetchingNextPage && (
                <Loading>
                    <LoadingSpinner size={25} thickness={2} />
                </Loading>
            )}

            {!isLoading && (
                <>
                    <DiscreetButton onClick={toggleDropdown}>
                        {isOpen ? 'Fechar' : 'Trocar'}
                    </DiscreetButton>
                    {isOpen && data && data.pages && data.pages.map((page, index) => (
                        <UserList users={page.data} changeUser={changeUser} />
                    ))}
                    {userOriginal && (
                        <DiscreetButton onClick={returnTokenAdmin}>
                            Voltar
                        </DiscreetButton>
                    )
                    }
                </>
            )}
        </Container>
    );
}

