import { SettingsModal } from "components/SettingsModal";
import { useAuth } from "hooks/useAuth";
import { FormEvent, useState } from "react";
import { useTheme } from "styled-components";
import { Container, Logout, SettingsIcon, UserProfile } from "./styles";

import { TextEllipsis } from "components/TextEllipsis";
import { UserDropdown } from "components/UserDropdown";
import { AdminList } from "types/User";
import { getTokenAdmin } from "utils/Auth";

export function LoggedUser() {
  const theme = useTheme();
  const { user, logout } = useAuth();
  const userOriginal = getTokenAdmin();

  const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false);

  function handleOpenSettingsModal() {
    setIsSettingsModalOpen(true);
  }

  function handleCloseSettingsModal() {
    setIsSettingsModalOpen(false);
  }

  function handleLogout(e: FormEvent) {
    e.preventDefault();
    logout();
  }

  return (
    <>
      <Container>
        <UserProfile>
          <TextEllipsis width={100}>
            {user?.name ?? 'Você'}
          </TextEllipsis>
          {
            ((userOriginal != null) || (user != null && AdminList.includes(user?.name))) && (
              <UserDropdown endpoint="/users" />
            )
          }
        </UserProfile>
        <Logout>
          <SettingsIcon
            onClick={handleOpenSettingsModal}
            color={theme.colors.text}
            size="16"
          />
          <a href="./" onClick={handleLogout}>Sair</a>
        </Logout>
      </Container>

      <SettingsModal
        isOpen={isSettingsModalOpen}
        onRequestClose={handleCloseSettingsModal}
      />
    </>
  );
}
