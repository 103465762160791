import { yupResolver } from '@hookform/resolvers/yup';
import { Input } from "components/Form/Input";
import { Form } from "styles/Form";
import { InputGroup } from "styles/InputGroup";
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import { SearchData, SearchTypes } from './types';
import { searchValidator } from './validation';
import { useContacts } from 'hooks/useContacts';
import { useContacts as useFetchContacts } from 'hooks/fetch/useContacts';
import { useEffect, useRef } from 'react';
import { LoadingSpinner } from 'components/LoadingSpinner';
import { LoadingIcon } from './styles';
import { useHistory } from 'react-router';

const validationSchema = searchValidator();


export function Search({ valueDataUrl }: SearchTypes) {
  const { currentEndpoint } = useContacts();
  const { query: { isFetching, isFetchingNextPage } } = useFetchContacts(currentEndpoint ?? '', false);

  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const history = useHistory();

  const { handleSubmit, control, setValue } = useForm<FieldValues>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      search: '',
      valueDataUrl: valueDataUrl,
    },
  });

  async function handleSearch({ search }: SearchData) {
    history.push(`/contatos/procurar/${search}`);
  }

  function handleKeyUp() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(() => {
      handleSubmit(handleSearch as SubmitHandler<FieldValues>)();
    }, 500);
  }

  useEffect(() => {
    setValue('search', valueDataUrl);
  }, [valueDataUrl, setValue]);

  return (
    <Form onSubmit={handleSubmit(handleSearch as SubmitHandler<FieldValues>)}>
      <InputGroup>
        <Input
          icons={isFetching && !isFetchingNextPage ? (
            <LoadingIcon>
              <LoadingSpinner size={15} thickness={1} />
            </LoadingIcon>
          ) : ''}
          control={control}
          type="search"
          name="search"
          id="search"
          padding=".8rem 4rem .8rem 1.5rem"
          placeholder="Procurar..."
          onKeyUp={handleKeyUp}
          fullwidth
        />
      </InputGroup>
    </Form>
  );
}
