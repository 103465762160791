import { Mic, Send } from "react-feather";
import { useTheme } from "styled-components";
import { Button } from "styles/Button";
import { SendMessageButtonProps } from "./types";

export function SendMessageButton({ formState, isRecordingAudio, onRecordAudioMessage, isAnnotation, lastMessage }: SendMessageButtonProps) {
  const theme = useTheme();
  const interactionStatus = () => {
    if (!lastMessage) return true;

    const lastReceivedDate = new Date(lastMessage);
    const now = new Date();
    const hoursDifference = Math.abs(now.getTime() - lastReceivedDate.getTime()) / 36e5;

    return hoursDifference > 24 ? true : false;
  };

  if (formState.dirtyFields.message || formState.dirtyFields.caption) {
    return (
      <Button type="submit" disabled={formState.isSubmitting || interactionStatus()}>
        <Send color={theme.colors.buttonText} size="18" />
      </Button>
    );
  } else if (onRecordAudioMessage && !isRecordingAudio) {
    return (
      <Button type="button" onClick={onRecordAudioMessage} disabled={interactionStatus() || isAnnotation}>
        <Mic color={theme.colors.buttonText} size="18" />
      </Button>
    );
  } else if (!isRecordingAudio) {
    return (
      <Button type="submit" disabled={formState.isSubmitting || interactionStatus()}>
        <Send color={theme.colors.buttonText} size="18" />
      </Button>
    );
  } else {
    return null;
  }
}
