import { yupResolver } from "@hookform/resolvers/yup";
import { ArchiveContactData } from "components/ArchiveContactModal/types";
import { archiveContactValidator } from "components/ArchiveContactModal/validation";
import { WHATSAPP_CHANNEL_ID } from "constants/remote";
import { useArchiveContact } from "hooks/mutations/useArchiveContact";
import { FieldValues, FormProvider, SubmitHandler, useForm, useWatch } from "react-hook-form";
import { Button } from "styles/Button";
import { Form as StyledForm } from 'styles/Form';
import { InputGroup } from "styles/InputGroup";
import * as Fields from '../../fields';
import { FormProps } from "./types";
import { FormRow, FormRowChannel } from "styles/FormRow";
import { useEffect } from "react";

const validationSchema = archiveContactValidator();

export function Form({ onRequestClose, contact }: FormProps) {
  const form = useForm<FieldValues>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      channel_id: WHATSAPP_CHANNEL_ID,
      archive_without_register: false,
      send_satisfaction: true,
      remove_responsible: false,
      remove_category: false,
    },
  });

  const { handleSubmit, setValue, control, reset, formState: { isSubmitting } } = form;

  const archiveWithoutRegister = useWatch({ name: 'archive_without_register', control });

  const hasResponsible = !!contact.responsibleId;
  const hasCategory = !!contact.categoryId;

  useEffect(() => {
    if (hasResponsible) setValue('remove_responsible', true);
    if (hasCategory) setValue('remove_category', true);
  }, [hasResponsible, hasCategory, setValue]);

  function handleRequestClose() {
    reset();
    onRequestClose();
  }

  const archiveContactMutation = useArchiveContact();

  async function handleArchiveWithoutRegister(data: ArchiveContactData) {
    await archiveContactMutation.mutateAsync({
      contactId: contact.id,
      data: {
        archive_without_register: data.archive_without_register,
        remove_responsible: typeof data.remove_responsible === 'boolean' ? data.remove_responsible : true,
        remove_category: typeof data.remove_category === 'boolean' ? data.remove_category : true,
        send_satisfaction: typeof data.send_satisfaction === 'boolean' ? data.send_satisfaction: true,
        lead_wrong: typeof data.lead_wrong === 'boolean' ? data.lead_wrong : false,
      },
    });
    handleRequestClose();
  }

  async function handleArchiveContact(data: ArchiveContactData) {
    if (data.archive_without_register) return handleArchiveWithoutRegister(data);

    await archiveContactMutation.mutateAsync({
      contactId: contact.id,
      data: {
        ...data,
        remove_responsible: typeof data.remove_responsible === 'boolean' ? data.remove_responsible : true,
        remove_category: typeof data.remove_category === 'boolean' ? data.remove_category : true,
      },
    });
    handleRequestClose();
  }

  return (
    <StyledForm onSubmit={handleSubmit(handleArchiveContact as SubmitHandler<FieldValues>)}>
      <FormProvider {...form}>
        {archiveWithoutRegister && (
          <>
            <FormRow>
              <Fields.ArchiveWithoutRegister />
              <Fields.RemoveResponsible hasResponsible={hasResponsible} />
            </FormRow>
            <FormRow>
              <Fields.RemoveCategory hasCategory={hasCategory} />
              <Fields.SendSatisfaction />
            </FormRow>
            <FormRow>
              <Fields.LeadWrong />
            </FormRow>
          </>
        )}

        {!archiveWithoutRegister && (
          <>
            <FormRow>
              <Fields.ArchiveWithoutRegister />
              <Fields.RemoveResponsible hasResponsible={hasResponsible} />
              <Fields.RemoveCategory hasCategory={hasCategory} />
            </FormRow>

            <Fields.CategoryId contactCategoryId={contact.categoryId} />
            <Fields.AttendanceContentId />
            <Fields.Annotation />
            <FormRowChannel>
              <Fields.ChannelId />
              <Fields.SendSatisfaction />
            </FormRowChannel>
          </>
        )}

        <InputGroup>
          <Button
            type="submit"
            fullwidth
            disabled={isSubmitting}
            accent
          >
            {isSubmitting ? 'Carregando...' : 'Arquivar contato'}
          </Button>
        </InputGroup>
      </FormProvider>
    </StyledForm>
  );
}
