import { ActionsButtons } from "components/ManageMacroModal/styles";
import { EditUserModal } from "components/EditUserModal";
import { Edit2, Trash2, UserX } from "react-feather";
import { useTheme } from "styled-components";
import { Button } from "styles/Button";
import { User } from "types/User";
import { useDeleteUser } from "hooks/mutations/useDeleteUser";
import Swal from "sweetalert2";
import { MouseEvent, useState } from 'react';
import { useRemoveUserContacts } from "hooks/mutations/useRemoveUserContacts";

export function UserCard({ user }: any) {
    const theme = useTheme();
    const [isEditUserModalOpen, setIsEditUserModalOpen] = useState(false);
    const [userToEdit, setUserToEdit] = useState<User>({} as User);

    const deleteUser = useDeleteUser();
    async function handleDeleteUser(e: MouseEvent, user: User) {
        e.stopPropagation();

        const willDelete = await Swal.fire({
            title: 'Excluir usuário!',
            text: 'Tem certeza que deseja excluir este usuário?',
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            showConfirmButton: true,
            confirmButtonText: 'Excluir usuário',
            confirmButtonColor: theme.colors.danger,
            reverseButtons: true,
        });

        if (!willDelete.isConfirmed) return false;

        await deleteUser.mutateAsync({ user });
    }

    const removeUserContacts = useRemoveUserContacts();
    async function handleRemoveContacts(e: MouseEvent, user: User) {
        e.stopPropagation();

        const willDelete = await Swal.fire({
            title: 'Desassociar contatos!',
            text: 'Tem certeza que deseja remover todos contatos deste usuário?',
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            showConfirmButton: true,
            confirmButtonText: 'Desassociar usuário',
            confirmButtonColor: theme.colors.danger,
            reverseButtons: true,
        });

        if (!willDelete.isConfirmed) return false;

        await removeUserContacts.mutateAsync({ user });
    }

    function handleEditUser(e: MouseEvent, user: User) {
        e.stopPropagation();
        handleOpenEditUserModal(user);
    }

    function handleOpenEditUserModal(user: User) {
        setUserToEdit(user);
        setIsEditUserModalOpen(true);
    }

    function handleCloseEditUserModal() {
        setUserToEdit({} as User);
        setIsEditUserModalOpen(false);
    }

    var category = 'Sem categoria';
    if (user.category !== null) {
        category = user.category?.name;
    }

    return (
        <>
            <tr key={user.id}>
                <td>{user.name}</td>
                <td>{user.email}</td>
                <td>{category}</td>
                <td>
                    <ActionsButtons>
                        <Button onClick={(e) => handleEditUser(e, user)} small>
                            <Edit2 color={theme.colors.buttonText} size="18" />
                        </Button>
                        <Button onClick={(e) => handleDeleteUser(e, user)} small>
                            <Trash2 color={theme.colors.buttonText} size="18" />
                        </Button>
                        <Button onClick={(e) => handleRemoveContacts(e, user)} small>
                            {user.all_opened_contacts_count}
                            <UserX color={theme.colors.buttonText} size="18" />
                        </Button>
                    </ActionsButtons>
                </td>
            </tr>

            <EditUserModal
                isOpen={isEditUserModalOpen}
                onRequestClose={handleCloseEditUserModal}
                userToEdit={userToEdit}
            />
        </>
    )
}