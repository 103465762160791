import { yupResolver } from '@hookform/resolvers/yup';
import { transferContactValidator } from 'components/TransferContactModal/validation';
import { FieldValues, FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { Button } from 'styles/Button';
import { Form as StyledForm } from 'styles/Form';
import { InputGroup } from 'styles/InputGroup';
import * as Fields from '../../fields';
import { FormProps } from './types';
import { useTransferContact } from 'hooks/mutations/useTransferContact';
import { TransferContactData } from 'components/TransferContactModal/types';
import { WHATSAPP_CHANNEL_ID } from 'constants/remote';

const validationSchema = transferContactValidator();

export function Form({ onRequestClose, contact }: FormProps) {
  const form = useForm<FieldValues>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      channel_id: WHATSAPP_CHANNEL_ID,
    },
  });

  const { reset, handleSubmit, formState: { isSubmitting } } = form;

  function handleRequestClose() {
    reset();
    onRequestClose();
  }

  const transferContactMutation = useTransferContact();

  async function handleTransferContact(data: TransferContactData) {
    await transferContactMutation.mutateAsync({ contactId: contact.id, data });
    handleRequestClose();
  }

  return (
    <StyledForm onSubmit={handleSubmit(handleTransferContact as SubmitHandler<FieldValues>)}>
      <FormProvider {...form}>
        <Fields.CategoryId contactCategoryId={contact.categoryId} />
        <Fields.AttendanceContentId />
        <Fields.Annotation />
        <Fields.WhatsAppAnnotation />
        <Fields.ChannelId />

        <InputGroup>
          <Button
            type="submit"
            fullwidth
            disabled={isSubmitting}
          >
            {isSubmitting ? 'Carregando...' : 'Transferir contato'}
          </Button>
        </InputGroup>
      </FormProvider>
    </StyledForm>
  );
}
