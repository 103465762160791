import { useUsers } from "hooks/fetch/useUsers";
import { ContentTable } from "pages/Users/styles";
import { Table } from "styles/Table";
import { LoadingSpinner } from "components/LoadingSpinner";
import { Loading, SmallLoading } from "components/ContactsTable/styles";
import { Fragment } from "react";
import { UserCard } from "components/UserCard";

export function UsersTable({ endpoint }: any) {
    const { loadMoreRef, query: { data, isLoading, isFetchingNextPage } } = useUsers(endpoint);
    return (
        <ContentTable>
            {isLoading && !isFetchingNextPage && (
                <Loading>
                    <LoadingSpinner size={25} thickness={2} />
                </Loading>
            )}

            {!isLoading && (
                <Table>
                    <thead>
                        <tr>
                            <th>Nome</th>
                            <th>E-mail</th>
                            <th>Categoria</th>
                            <th>Ações</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {data && data.pages && data.pages.map((page, index) => (
                            <Fragment key={index}>
                                {page.data && page.data.map((user) => {
                                    return (
                                        <UserCard
                                            key={user.id}
                                            user={user}
                                        />
                                    )
                                })}
                            </Fragment>
                        ))}
                    </tbody>
                </Table>
            )}

            <div ref={loadMoreRef} style={{ visibility: 'hidden' }}></div>

            {isFetchingNextPage && (
                <SmallLoading>
                    <LoadingSpinner size={25} thickness={2} />
                </SmallLoading>
            )} 
        </ContentTable>
    )

}