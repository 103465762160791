import Modal from 'react-modal';
import { yupResolver } from "@hookform/resolvers/yup";
import { Control, FieldValues, SubmitHandler, UseFormSetError, useForm } from "react-hook-form";

import { Input } from "components/Form/Input";

import { ModalTitle } from "styles/Modal";
import { Form } from "styles/Form";
import { InputGroup } from "styles/InputGroup";
import { Button } from "styles/Button";

import { AddCategoryModalProps } from "./types";

import { categoryValidator } from "./validation";

import closeImg from '../../assets/close.svg';
import { useCreateCategory } from 'hooks/mutations/useCreateCategory';
import { CreateCategoryData } from 'types/Category';

const validationSchema = categoryValidator();

export function AddCategoryModal({ isOpen, onRequestClose }: AddCategoryModalProps) {
  const { handleSubmit, setError, control, reset, formState: { isSubmitting } } = useForm<FieldValues>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      name: '',
    },
  });

  function handleRequestClose() {
    reset();
    onRequestClose();
  }

  const createCategory = useCreateCategory(setError as UseFormSetError<FieldValues>);

  async function handleCreateNewCategory({ name }: CreateCategoryData) {
    await createCategory.mutateAsync({ data: { name } });
    handleRequestClose();
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleRequestClose}
      overlayClassName="react-modal-overlay"
      className="react-modal-content"
    >
      <button
        type="button"
        onClick={handleRequestClose}
        className="react-modal-close"
      >
        <img src={closeImg} alt="Fechar modal" loading="lazy" />
      </button>

      <div>
        <ModalTitle>Criar setor</ModalTitle>

        <Form onSubmit={handleSubmit(handleCreateNewCategory as SubmitHandler<FieldValues>)}>
          <InputGroup>
            <Input
              control={control as Control<FieldValues>}
              type="text"
              name="name"
              id="name"
              label="Nome"
              placeholder="Nome do setor"
              maxLength={255}
              fullwidth
            />
          </InputGroup>
          <InputGroup>
            <Button
              type="submit"
              fullwidth
              disabled={isSubmitting}
            >
              {isSubmitting ? 'Carregando...' : 'Criar setor'}
            </Button>
          </InputGroup>
        </Form>
      </div>
    </Modal>
  );
}
