import styled from "styled-components";

export const FormRow = styled.div`
  display: flex;
  width: 100%;
  gap: 1rem;
`;

export const FormRowChannel = styled.div`
  display: flex;
  width: 100%;
  gap: 1rem;

  & > *:first-child {
    flex: 2;
  }

  & > *:last-child {
    flex: 1;
  }
`;
