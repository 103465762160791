import { yupResolver } from "@hookform/resolvers/yup";
import { Control, FieldValues, SubmitHandler, UseFormSetError, useForm } from "react-hook-form";
import Modal from 'react-modal';

import { Input } from "components/Form/Input";

import { Button } from "styles/Button";
import { Form } from "styles/Form";
import { InputGroup } from "styles/InputGroup";
import { ModalTitle } from "styles/Modal";

import { EditMacroModalProps } from "./types";

import { macroValidator } from "./validation";

import { useEditMacro } from 'hooks/mutations/useEditMacro';
import { useEffect, useState } from 'react';
import { MacroTextarea } from 'styles/MacroTextarea';
import { EditMacroData } from 'types/Macro';
import closeImg from '../../assets/close.svg';
import { Category } from "types/Category";
import { Dropdown } from "components/Dropdown";
import { CategoryList } from "components/EditUserModal/components/CategoryList";

const validationSchema = macroValidator();

export function EditMacroModal({ isOpen, onRequestClose, macroToEdit }: EditMacroModalProps) {
  const { handleSubmit, setValue, setError, control, reset, formState: { isSubmitting } } = useForm<FieldValues>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      title: '',
      message: '',
      category: '',
      category_id: '',
    },
  });

  useEffect(() => {
    setValue('title', macroToEdit.title ?? '');
    setValue('message', macroToEdit.message ?? '');
    setValue('category', macroToEdit.category_id ?? '');
  }, [setValue, macroToEdit])

  function handleRequestClose() {
    reset();
    onRequestClose();
  }

  const editMacro = useEditMacro(setError as UseFormSetError<FieldValues>);
  const [selectedCategory, setSelectedCategory] = useState<Category | null>(null);

  function handleChangeUserCategory(category: Category) {
    setValue('category_id', category.id);
    setSelectedCategory(category);
  }

  async function handleEditMacro({ title, message, category_id }: EditMacroData) {
    await editMacro.mutateAsync({ macroToEdit: macroToEdit, data: { title, message, category_id } });
    handleRequestClose();
    setSelectedCategory(null);
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleRequestClose}
      overlayClassName="react-modal-overlay"
      className="react-modal-content big"
    >
      <button
        type="button"
        onClick={handleRequestClose}
        className="react-modal-close"
      >
        <img src={closeImg} alt="Fechar modal" loading="lazy" />
      </button>

      <div>
        <ModalTitle>Editar macro</ModalTitle>

        <Form onSubmit={handleSubmit(handleEditMacro as SubmitHandler<FieldValues>)}>
          <InputGroup>
            <Input
              control={control as Control<FieldValues>}
              type="text"
              name="title"
              id="title"
              label="Título"
              placeholder="Título do macro"
              maxLength={255}
              fullwidth
            />
          </InputGroup>
          <InputGroup>
            <MacroTextarea
              control={control as Control<FieldValues>}
              name="message"
              id="macroMessage"
              label="Mensagem"
              placeholder="Mensagem do macro"
              rows={4}
              fullwidth
            />
          </InputGroup>
          <InputGroup style={{ paddingBottom: '70px' }}>
            <Dropdown
              trigger={
                <Button
                  type="button"
                  medium={!window.matchMedia("(max-width: 1366px)").matches}
                  small={window.matchMedia("(max-width: 1366px)").matches}
                  dropdown
                >
                  {selectedCategory ? selectedCategory.name : 'Setor'}
                </Button>
              }
              closeOnClickInside={true}
            >
              <CategoryList
                handleChangeUserCategory={handleChangeUserCategory}
              />
            </Dropdown>
          </InputGroup>
          <InputGroup>
            <Button type="submit" fullwidth disabled={isSubmitting}>
              {isSubmitting ? 'Carregando...' : 'Editar macro'}
            </Button>
          </InputGroup>
        </Form>
      </div>
    </Modal>
  );
}
