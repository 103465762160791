import { useAuth } from "hooks/useAuth";
import { useAssumeContact } from "hooks/mutations/useAssumeContact";
import { useContacts } from "hooks/useContacts";
import { Button } from "styles/Button";
import { AssumeContactButtonProps } from "./types";

export function AssumeContactButton({ onAssumeContact }: AssumeContactButtonProps) {
  const { user } = useAuth();
  const admins = ["Henrique Fernandes", "Ivan Vitorassi", "Felipe Francisco"];
  const assumeContactMutation = useAssumeContact();

  const { selectedContact } = useContacts();

  async function handleAssumeContact() {
    await assumeContactMutation.mutateAsync({ contact: selectedContact });
    onAssumeContact();
  }

  // desativa o botão fora do horário comercial e fim de semana
  if ((user != null && !admins.includes(user.name)) && (new Date().getDay() === 0 || new Date().getDay() === 6) || (new Date().getHours() < 8 || new Date().getHours() > 18)){
    return (
      <Button 
        type="button" 
        medium={!window.matchMedia("(max-width: 1366px)").matches} 
        small={window.matchMedia("(max-width: 1366px)").matches}
        title="Fora do horário comercial"
        disabled>
        Assumir
      </Button>
    );
  }
  else {
    return (
      <Button
        type="button"
        medium={!window.matchMedia("(max-width: 1366px)").matches}
        small={window.matchMedia("(max-width: 1366px)").matches}
        onClick={handleAssumeContact}>
        Assumir
      </Button>
    );
  }
}
