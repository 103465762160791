import { AxiosError } from "axios";
import { useContacts } from "hooks/useContacts";
import { useCount } from "hooks/utils/useCount";
import { useMutation } from "react-query";
import { api } from "services/api";
import { Tag } from "types/Tag";
import { Contact } from "types/Contact";
import { axiosError, axiosSuccess, handleRequestError, handleRequestSuccess } from "utils/Request";

type ChangeContactTagProps = {
  contact: Contact;
  tag: Tag;
}

type RemoveContactTagProps = {
  contact: Contact;
}

async function changeContactTag(contact: Contact, tag: Tag): Promise<Contact> {
  const { data } = await api.post(`/contacts/${contact.id}/change-tag/`, { tag_id: tag.id })
    .then(axiosSuccess)
    .catch(axiosError);
  return data;
}

async function removeContactTag(contact: Contact): Promise<Contact> {
  const { data } = await api.post(`/contacts/${contact.id}/change-tag/`, { tag_id: null })
    .then(axiosSuccess)
    .catch(axiosError);
  return data;
}

export function useChangeContactTag() {
  const { selectContact } = useContacts();
  const { revalidateCount } = useCount();

  return useMutation(({ contact, tag }: ChangeContactTagProps) => changeContactTag(contact, tag), {
    onSuccess: (data: Contact) => {
      handleRequestSuccess(`Tag alterada com sucesso!`);
      selectContact(data);
      revalidateCount();
    },
    onError: (error: AxiosError) => {
      handleRequestError(error, 'Um erro ocorreu ao editar a tag do contato. Por favor, tente novamente!');
    }
  });
}

export function useRemoveContactTag() {
  const { selectContact } = useContacts();
  const { revalidateCount } = useCount();

  return useMutation(({ contact }: RemoveContactTagProps) => removeContactTag(contact), {
    onSuccess: (data: Contact) => {
      handleRequestSuccess(`Tag removida com sucesso!`);
      selectContact(data);
      revalidateCount();
    },
    onError: (error: AxiosError) => {
      handleRequestError(error, 'Um erro ocorreu ao remover a tag do contato. Por favor, tente novamente!');
    }
  });
}
