import useIntersectionObserver from "hooks/useIntersectionObserver";
import { useEffect, useRef } from "react";
import { useInfiniteQuery } from "react-query";
import { api } from "services/api";
import { PaginatedUsers } from "types/User";
import { handleRequestError } from "utils/Request";

export async function fetchUsers(page: number, url: string): Promise<PaginatedUsers> {
    if (!page || !url) return {} as PaginatedUsers;
  
    const { data } = await api.get(url, {
      params: { page },
    });
    return data;
  }
  
  export function useUsers(url: string, enabledOnStart = true) {
    const query = useInfiniteQuery(
      ['users', url],
      ({ pageParam = 1 }) => fetchUsers(pageParam, url),
      {
        keepPreviousData: true,
        enabled: enabledOnStart,
        getPreviousPageParam: (res => {
          if (res.current_page - 1 >= 1) return res.current_page - 1;
          return false;
        }),
        getNextPageParam: (res => {
          if (res.current_page + 1 <= res.last_page) return res.current_page + 1;
          return false;
        }),
      }
    );
  
    const loadMoreRef = useRef(null);
  
    useIntersectionObserver({
      target: loadMoreRef,
      onIntersect: query.fetchNextPage,
      enabled: query.hasNextPage,
    });
  
    useEffect(() => {
      if (!query.isError) return;
      handleRequestError(query.error, 'Um erro ocorreu ao listar os usuários. Por favor, tente novamente!');
    }, [query.isError, query.error]);
  
    return { loadMoreRef, query };
  }
  