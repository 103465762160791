import { Category } from "./Category";

export type User = {
  id: string;
  name: string;
  email: string;
  phone: string;
  image_url: string | null;
  default_category_id: string | null;
  contacts_with_new_messages_count: number;
  all_opened_contacts_count: number;
  category: Category | null;
  company_id: string;
}

export const AdminList = ["Henrique Fernandes", "Matheus Mesquita", "Ivan Vitorassi", "Felipe Francisco"];

export type PaginatedUsers = {
  current_page: number;
  last_page: number;
  data: User[];
}

export type CreateUserData = {
  name: string;
  email: string;
  password: string;
  password_confirmation: string;
  default_category_id: string;
  company_id: string;
}