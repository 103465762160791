import { LoadingSpinner } from "components/LoadingSpinner";
import { useLocation } from "react-router";
import { Loading } from "./styles";
import { SidenavLink } from "components/SidenavLink";
import { TagMenuListProps } from "./types";

export function TagMenuList({ tags, isLoading }: TagMenuListProps) {
  const { search } = useLocation();

  return (
    <>
      {isLoading && (
        <Loading>
          <LoadingSpinner size={20} thickness={2} />
        </Loading>
      )}

      {!isLoading && tags && tags.map((tag) => (
        <SidenavLink
          key={tag.id}
          to={{ pathname: `/contatos/tag/${tag.id}`, search }}
          contactsWithNewMessages={tag.countNotRead}
          contactsCount={tag.count}
        >
          - {tag.name}
        </SidenavLink>
      ))}
    </>
  );
}
