import { InputGroup } from "../../styles/InputGroup";

import { yupResolver } from '@hookform/resolvers/yup';
import { useHistory } from "react-router-dom";

import { Container, Content } from "./styles";

import { useAuth } from "../../hooks/useAuth";
import { Input } from "components/Form/Input";
import { Button } from "styles/Button";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import { SignInCredentials } from "types/Auth";
import { handleFormErrors } from "utils/Form";
import { Form } from "styles/Form";
import { loginValidator } from "./validation";

const validationSchema = loginValidator();

export function Login() {
  const { login } = useAuth();
  const history = useHistory();

  const { handleSubmit, setError, control, formState: { isSubmitting } } = useForm<FieldValues>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      email: '',
      password: '',
      remember: false,
    },
  });

  async function onSubmit({ email, password, remember }: SignInCredentials) {
    try {
      await login({ email, password, remember });
      history.replace('/');
    } catch (error) {
      handleFormErrors(error, setError);
    }
  }

  return (
    <Container>
      <Content>
        <h1>Entrar</h1>

        <Form onSubmit={handleSubmit(onSubmit as SubmitHandler<FieldValues>)}>
          <InputGroup>
            <Input
              control={control}
              type="email"
              id="email"
              label="Email"
              placeholder="email@email.com"
              name="email"
              autoFocus
              fullwidth
            />
          </InputGroup>

          <InputGroup>
            <Input
              control={control}
              type="password"
              id="password"
              label="Password"
              placeholder="********"
              name="password"
              fullwidth
            />
          </InputGroup>

          {/* <InputGroup>
            <Checkbox
              control={control}
              id="remember"
              label="Lembre-se de mim"
              name="remember"
            />
          </InputGroup> */}

          <InputGroup>
            <Button type="submit" fullwidth disabled={isSubmitting}>
              {isSubmitting ? 'Carregando...' : 'Entrar'}
            </Button>
          </InputGroup>
        </Form>
      </Content>
    </Container>
  )
}
