import { NotFound } from "pages/NotFound";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { Home } from "../pages/Home";
import { Users } from "../pages/Users";

function AppRoutes() {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>
        <Route path="/contatos/*">
          <Home />
        </Route>
        <Route exact path="/users">
          <Users />
        </Route>
        <Route path="/login">
          <Redirect to="/" />
        </Route>
        <Route path="*">
          <NotFound />
        </Route>
      </Switch>
    </BrowserRouter>
  )
}

export default AppRoutes;
