import { AxiosError } from "axios";
import { useMutation, useQueryClient } from "react-query";
import { api } from "services/api";
import { User } from "types/User";
import { axiosError, axiosSuccess, handleRequestError, handleRequestSuccess } from "utils/Request";

type RemoveUserContactsProps = {
  user: User;
}

async function removeUserContacts(user: User): Promise<User> {
  const { data } = await api.post(`/contacts/removeUser/${user.id}`).then(axiosSuccess).catch(axiosError);
  return data;
}

export function useRemoveUserContacts() {

  const queryClient = useQueryClient();

  return useMutation(({ user }: RemoveUserContactsProps) => removeUserContacts(user), {
    onSuccess: () => {
      handleRequestSuccess('Os contatos foram desassociados com sucesso!');
      queryClient.invalidateQueries('users');
    },
    onError: (error: AxiosError) => {
      handleRequestError(error, 'Um erro ocorreu ao desassociar os contatos. Por favor, tente novamente!');
    }
  });
}
