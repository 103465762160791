import { useState } from "react";
import { Button } from "styles/Button";
import { CreateUserModal } from "./components/CreateUserModal";

export function CreateUser() {
    const [isCreateUserModalOpen, setIsCreateUserModalOpen] = useState(false);

    function handleOpenCreateModal() {
        setIsCreateUserModalOpen(true);
    }

    function handleCloseCreateUserModal() {
        setIsCreateUserModalOpen(false);
    }

    return (
        <>
            <div>
                <Button onClick={handleOpenCreateModal}>
                    Criar usuário
                </Button>
            </div>
            <CreateUserModal
                isOpen={isCreateUserModalOpen}
                onRequestClose={handleCloseCreateUserModal}
            />
        </>
    )
}