import { notificationSound, transferredContactSound } from "App";

export function playNotificationSound() {
  function saveLastNotificationTime() {
    const now = new Date();
    localStorage.setItem('lastNotificationTime', now.getTime().toString());
  }

  const lastNotificationTime = localStorage.getItem('lastNotificationTime');

  if (lastNotificationTime !== null) {
    const currentTime = new Date().getTime();
    const timeDiffInSeconds = (currentTime - parseInt(lastNotificationTime, 10)) / 1000;

    if (!isNaN(timeDiffInSeconds) && timeDiffInSeconds >= 10) {
      saveLastNotificationTime();
      return notificationSound.play();
    }
  } else {
    saveLastNotificationTime();
    return notificationSound.play();
  }
}

export function playTransferredContactSound() {
  return transferredContactSound.play();
}