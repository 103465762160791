import { AxiosError, AxiosResponse } from "axios";
import { DeleteMacroProps } from "hooks/mutations/useDeleteMacro";
import { UseMutationResult } from "react-query";
import { DefaultTheme } from "styled-components";
import Swal, { SweetAlertOptions } from "sweetalert2";
import { Macro } from "types/Macro";

type DeleteMacroData = {
  modalOptions?: SweetAlertOptions;
  mutation: UseMutationResult<AxiosResponse<any>, AxiosError<any>, DeleteMacroProps, unknown>
  currentTheme: DefaultTheme;
  macro: Macro;
}

export async function deleteMacro({
  modalOptions = {},
  currentTheme,
  mutation,
  macro,
}: DeleteMacroData) {
  const willDelete = await Swal.fire({
    title: 'Excluir macro!',
    text: 'Tem certeza que deseja excluir este macro?',
    icon: 'warning',
    showCancelButton: true,
    cancelButtonText: 'Cancelar',
    showConfirmButton: true,
    confirmButtonText: 'Excluir macro',
    confirmButtonColor: currentTheme?.colors?.danger,
    reverseButtons: true,
    ...modalOptions,
  });

  if (!willDelete.isConfirmed) return false;

  await mutation.mutateAsync({ macro });
}
