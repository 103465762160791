import { createContext, useEffect, useState } from "react";
import { SocketContextData, SocketProviderProps } from "types/Socket";
import Echo, { Channel } from "laravel-echo";
import Pusher from 'pusher-js';
declare global {
  interface Window {
    Pusher: typeof Pusher;
  }
}

if (typeof window !== 'undefined') {
  window.Pusher = Pusher;
}

export const SocketContext = createContext({} as SocketContextData);

export function SocketProvider({ children, user }: SocketProviderProps) {
  const [channelPublic, setChannelPublic] = useState({} as Channel);
  const [channelPrivate, setChanelPrivate] = useState({} as Channel);

  useEffect(() => {
    const echo = new Echo({
      broadcaster: 'reverb',
      key: process.env.REACT_APP_SOCKET_TOKEN,
      wsPort: process.env.REACT_APP_SOCKET_PORT,
      wsHost: process.env.REACT_APP_SOCKET_URL,
      forceTLS: false,
      enabledTransports: ['ws'],
    })

    const channelPublic = echo.channel('whatsapp')
    const channelPrivate = echo.channel('whatsapp.' + user?.id)

    setChannelPublic(channelPublic);
    setChanelPrivate(channelPrivate);
  }, [user]);

  return (
    <SocketContext.Provider value={{ channelPublic, channelPrivate }}>
      {children}
    </SocketContext.Provider>
  )
}
