import cx from 'classnames';
import { formatDistanceStrict } from "date-fns";

import { Badge } from "components/Badge";
import { TextEllipsis } from "components/TextEllipsis";

import { strToDateTime } from "utils/Datetime";

import { ContactName, Container, Info, MessagesCount, Nps, ProfilePicture, SearchedMessage } from "./styles";

import { ContactProps } from "./types";

import avatarImg from '../../assets/avatar.jpg';

import { ArchiveContactModal } from "components/ArchiveContactModal";
import { ImageModal } from "components/ImageModal";
import { pt } from 'date-fns/locale';
import { useContacts } from "hooks/useContacts";
import { MouseEvent, useState } from "react";
import { Archive } from "react-feather";
import { useTheme } from 'styled-components';
import { Button } from "styles/Button";
import { Count } from "styles/Count";
import { Muted } from "styles/Muted";
import { Contact as ContactType } from "types/Contact";
import { useAuth } from 'hooks/useAuth';
import { TagView } from 'components/TagView';

export function Contact({
  isInactive,
  contact,
}: ContactProps) {
  const { name, nps, id, archived_at, image_url, last_seen, new_messages_count, user, category, message, tag, stage_cs, last_received_at } = contact;

  const [isArchiveContactModalOpen, setIsArchiveContactModalOpen] = useState(false);
  const [isProfilePictureModalOpen, setIsProfilePictureModalOpen] = useState(false);

  const { selectedContact, selectContact } = useContacts();

  const { user: loggedUser } = useAuth();

  const theme = useTheme();

  function handleCloseProfilePictureModal() {
    setIsProfilePictureModalOpen(false);
  }

  function handleOpenProfilePictureModal() {
    setIsProfilePictureModalOpen(true);
  }

  function handleContactProfilePictureClick(e: MouseEvent<HTMLImageElement>) {
    e.stopPropagation();
    handleOpenProfilePictureModal();
  }

  async function handleContactSelection(contact: ContactType) {
    await selectContact(contact);
  }

  function handleOpenArchiveModal() {
    setIsArchiveContactModalOpen(true);
  }

  function handleCloseArchiveModal() {
    setIsArchiveContactModalOpen(false);
  }

  const interactionStatus = () => {
    if (!last_received_at) return "Sem interação recente";

    const lastReceivedDate = new Date(last_received_at);
    const now = new Date();
    const hoursDifference = Math.abs(now.getTime() - lastReceivedDate.getTime()) / 36e5;

    return hoursDifference > 24 ? "Sem interação recente" : '';
  };

  return (
    <>
      <Container
        data-tip={isInactive
          ? 'Não foi constada nenhuma interação com este usuário nos últimos 3 dias, portanto o mesmo foi marcado como estagnado.'
          : ''
        }
        className={cx({
          inactive: isInactive,
          selected: id === selectedContact.id,
        })}
        key={id}
        onClick={() => handleContactSelection(contact)}
      >
        <td>
          <ProfilePicture>
            <img
              src={image_url ?? avatarImg}
              alt={name} loading="lazy"
              onClick={handleContactProfilePictureClick}
              onError={(e) => e.currentTarget.src = avatarImg}
            />
            {new_messages_count > 0 && (
              <MessagesCount>
                <Count>{new_messages_count}</Count>
              </MessagesCount>
            )}
          </ProfilePicture>
          <Info>
            <div>
              <ContactName width={150}>
                {name}
              </ContactName>
              <Nps width={150}>

                {nps != null ? 'NPS ' + JSON.parse(nps).nps : ''}
              </Nps>
              <TextEllipsis width={200}>
                {interactionStatus()}
              </TextEllipsis>
              {!!message?.body && (
                <SearchedMessage>
                  <TextEllipsis width={200}>
                    Msg: {message.body}
                  </TextEllipsis>
                </SearchedMessage>
              )}
            </div>
          </Info>
        </td>
        <td title={last_seen ? strToDateTime(last_seen) : 'última interação não registrada'}>
          <TextEllipsis width={110}>
            {last_seen
              ? formatDistanceStrict(
                new Date(last_seen),
                new Date(),
                { addSuffix: true, locale: pt }
              )
              : <Muted>N/A</Muted>
            }
            {stage_cs && (
              <>
                <br />
                Estágio: <TagView backgroundColor="#029306" color="#FFFFFF">{stage_cs}</TagView>
              </>
            )}
          </TextEllipsis>
        </td>
        <td>
          <TextEllipsis width={100}>
            {user?.name ?? <Muted>N/A</Muted>}
          </TextEllipsis>
          <br />
          {tag?.name != null ? <TagView>{tag.name}</TagView> : ''}
        </td>
        <td>
          {category?.name ? <Badge>{category?.name}</Badge> : <Muted>N/A</Muted>}
        </td>
        <td>
          {(user?.id === loggedUser?.id || !user?.id) && (
            <Button disabled={!!archived_at} onClick={handleOpenArchiveModal} small accent>
              <Archive color={theme.colors.buttonText} size="12" />
            </Button>
          )}
        </td>
      </Container>

      {isProfilePictureModalOpen && <ImageModal
        isOpen={isProfilePictureModalOpen}
        onRequestClose={handleCloseProfilePictureModal}
        imageUrl={image_url ?? avatarImg}
      />}

      <ArchiveContactModal
        contact={{ id, name, categoryId: category?.id, responsibleId: user?.id }}
        isOpen={isArchiveContactModalOpen}
        onRequestClose={handleCloseArchiveModal}
      />
    </>
  );
}
