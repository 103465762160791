import { Checkbox } from "components/Form/Checkbox";
import { useFormContext } from "react-hook-form";
import { InputGroup } from "styles/InputGroup";

export function LeadWrong() {
  const { control } = useFormContext();

  return (
    <InputGroup flexItem>
      <Checkbox name="lead_wrong" control={control} id="lead_wrong" label="Não é corretor/imobiliária, falha na captação" />
    </InputGroup>
  );
}
