import styled from "styled-components";

interface ContainerProps {
  backgroundColor?: string;
  color?: string;
}

export const Container = styled.div<ContainerProps>`
  display: inline-block;
  background: ${props => props.backgroundColor || props.theme.colors.annotation};
  color: ${props => props.color || props.theme.colors.automaticAnnotationText};
  padding: .25rem .5rem;
  font-size: 0.875rem;
  margin-top: 0.2rem;
`;
