import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: auto;
  background: ${props => props.theme.colors.contentBackground};
  color: ${props => props.theme.colors.text};
`;

export const UserListUl = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  position: absolute;
  z-index: 99;
  border: 2px solid ${props => props.theme.colors.border};
  background: ${props => props.theme.colors.contentBackground};
`;

export const UserListItem = styled.li`
  padding: 4px;
  transition: background 0.2s;
  cursor: pointer;

  &:hover {
    color: ${props => props.theme.colors.hoveredText};
  }
`;

export const DiscreetButton = styled.p`
  color: ${props => props.theme.colors.text};
  cursor: pointer;
  padding: 5px 2px;
  width: 70%;
  border-radius: 4px;
  border: 1px solid ${props => props.theme.colors.border};

  &:hover {
    color: ${props => props.theme.colors.hoveredText};
  }
`;