import { AxiosError } from "axios";
import { useMutation, useQueryClient } from "react-query";
import { api } from "services/api";
import { User } from "types/User";
import { axiosError, axiosSuccess, handleRequestError, handleRequestSuccess } from "utils/Request";

export type DeleteUserProps = {
  user: User;
}

function deleteUser(user: User) {
  return api.delete(`/users/${user.id}`).then(axiosSuccess).catch(axiosError);
}

export function useDeleteUser() {
  const queryClient = useQueryClient();

  return useMutation(({ user }: DeleteUserProps) => deleteUser(user), {
    onSuccess: () => {
      handleRequestSuccess('Usuário excluido com sucesso!');
      queryClient.invalidateQueries('users');
    },
    onError: (error: AxiosError) => {
      handleRequestError(error, 'Um erro ocorreu ao excluir o usuário. Por favor, tente novamente!');
    }
  });
}
