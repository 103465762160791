import { Dropdown } from "components/Dropdown";

import { Button } from "styles/Button";

import { TagList } from "components/TagList";

export function TagsButton() {
  return (
    <>
      <Dropdown
        trigger={
          <Button
            type="button"
            medium={!window.matchMedia("(max-width: 1366px)").matches}
            small={window.matchMedia("(max-width: 1366px)").matches}
            dropdown
          >
            Tags
          </Button>
        }
        closeOnClickInside={true}
      >
        <TagList />
      </Dropdown>
    </>
  );
}
