import { AxiosError } from "axios";
import { FieldValues, UseFormSetError } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import { api } from "services/api";
import { CreateUserData } from "types/User";
import { handleFormErrors } from "utils/Form";
import { axiosError, axiosSuccess, handleRequestSuccess } from "utils/Request";

type CreateUserProps = {
  data: CreateUserData;
}

async function createUser(newData: CreateUserData): Promise<CreateUserData> {
  const { data } = await api.post('/auth/register', { ...newData }).then(axiosSuccess).catch(axiosError);
  return data;
}

export function useCreateUser(errorSetter: UseFormSetError<FieldValues>) {
  const queryClient = useQueryClient();

  return useMutation(({ data }: CreateUserProps) => createUser(data), {
    onSuccess: (data: any) => {
      handleRequestSuccess(`Usuário "${data.user.name}" cadastrado com sucesso!`);
      queryClient.invalidateQueries('users');
      queryClient.invalidateQueries('count');
    },
    onError: (error: AxiosError) => {
      handleFormErrors(error, errorSetter);
    }
  });
}