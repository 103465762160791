// import swal from "sweetalert";
import { DropdownListItem } from "components/Dropdown/styles";
import { LoadingSpinner } from "components/LoadingSpinner";
import { useTags } from "hooks/fetch/useTags";
import { useChangeContactTag, useRemoveContactTag } from "hooks/mutations/useChangeContactTag";
import { useContacts } from "hooks/useContacts";
import { Minus } from "react-feather";
import { useTheme } from "styled-components";
import { Container, Loading } from "./styles";
import { Tag } from "types/Tag";

export function TagList() {
  const theme = useTheme();
  const { selectedContact } = useContacts();

  const { data, isLoading } = useTags();

  const removeContactTag = useRemoveContactTag();

  async function handleRemoveContactTag() {
    await removeContactTag.mutateAsync({ contact: selectedContact });
  }

  const changeContactTag = useChangeContactTag();

  async function handleChangeContactTag(tag: Tag) {
    await changeContactTag.mutateAsync({ contact: selectedContact, tag });
  }

  return (
    <Container>
      <DropdownListItem onClick={handleRemoveContactTag} danger>
        <Minus color={theme.colors.danger} size="18" />
        <span>Remover tag</span>
      </DropdownListItem>

      {isLoading && (
        <Loading>
          <LoadingSpinner size={15} thickness={1} />
        </Loading>
      )}

      {!isLoading && data && data.map((tag) => (
        <DropdownListItem onClick={() => handleChangeContactTag(tag)} key={tag.id} title={tag.name}>
          <span>{tag.name}</span>
        </DropdownListItem>
      ))}
    </Container>
  );
}
