import { useEffect } from "react";
import { useQuery } from "react-query";
import { api } from "services/api";
import { Tag } from "types/Tag";
import { handleRequestError } from "utils/Request";

export async function fetchTags(): Promise<Tag[]> {
  const { data } = await api.get('/tags');
  return data;
}

export function useTags() {
  const query = useQuery('tags', fetchTags);

  useEffect(() => {
    if (!query.isError) return;
    handleRequestError(query.error, 'Ocorreu um erro ao listar as tags. Por favor, tente novamente.');
  }, [query.isError, query.error]);

  return query;
}
