import Modal from 'react-modal';
import Switch from 'react-switch';

import { SettingsModalProps } from "./types";

import { Table } from 'styles/Table';

import closeImg from '../../assets/close.svg';
import { shade } from 'polished';
import { useTheme } from 'styled-components';
import { useDarkMode } from 'hooks/useDarkMode';
import { ModalTitle } from 'styles/Modal';
import { useAudio } from 'hooks/useAudio';
import { PlaybackSpeed } from 'components/AudioMessage/styles';
import { useLocalStorage } from 'hooks/useLocalStorage';
import { useEffect } from 'react';
import { AVAILABLE_AUDIO_SPEEDS } from 'constants/config';
import { OptionManageUsers } from './components/OptionManageUsers';

export function SettingsModal({ isOpen, onRequestClose }: SettingsModalProps) {
  const theme = useTheme();

  const { isDarkMode, toggle } = useDarkMode();

  const { changeSpeed, currentSpeed } = useAudio(AVAILABLE_AUDIO_SPEEDS);

  const [, setDefaultAudioSpeed] = useLocalStorage<number>(
    '@WhatsCodex:defaultAudioSpeed',
    1,
  );

  useEffect(() => {
    if (!currentSpeed) return;
    setDefaultAudioSpeed(currentSpeed);
  }, [currentSpeed, setDefaultAudioSpeed]);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      overlayClassName="react-modal-overlay"
      className="react-modal-content"
    >
      <button
        type="button"
        onClick={onRequestClose}
        className="react-modal-close"
      >
        <img src={closeImg} alt="Fechar modal" loading="lazy" />
      </button>

      <div>
        <ModalTitle>Configurações</ModalTitle>

        <Table>
          <thead>
            <tr>
              <th>Configuração</th>
              <th>Preferência</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Modo escuro</td>
              <td>
                <Switch
                  onChange={toggle}
                  checked={isDarkMode}
                  checkedIcon={false}
                  uncheckedIcon={false}
                  height={10}
                  width={40}
                  handleDiameter={20}
                  offHandleColor={theme.colors.switchHandler}
                  onHandleColor={theme.colors.switchHandler}
                  offColor={shade(0.15, theme.colors.main)}
                  onColor={theme.colors.primary}
                />
              </td>
            </tr>
            <tr>
              <td>Velocidade padrão de áudios</td>
              <td>
                <PlaybackSpeed
                  isAudioFile={false}
                  onClick={changeSpeed}
                >
                  {currentSpeed}x
                </PlaybackSpeed>
              </td>
            </tr>

            <OptionManageUsers/>
          </tbody>
        </Table>
      </div>
    </Modal>
  );
}
