import { ArrowRightCircle, File } from "react-feather";
import { useTheme } from "styled-components";
import { MessageTitle } from "styles/MessageTitle";
import { Container, ContentWrapper, DocumentIconWrapper, DocumentWrapper, DownloadIcon } from "./styles";
import { DocumentMessageProps } from "./types";
import { Caption } from "components/VideoQuote/styles";
import { formatMessage } from "utils/MessageContent";

export function DocumentMessage({ message }: DocumentMessageProps) {
  const theme = useTheme();

  return (
    <Container>
      {message.is_forwarded && (
        <MessageTitle spaced={false}>
          <ArrowRightCircle size="18" color={theme.colors.text} />
          Encaminhada
        </MessageTitle>
      )}

      <DocumentWrapper>
        <DocumentIconWrapper>
          <File size="16" />
        </DocumentIconWrapper>
        <ContentWrapper>
          <p>Documento anexado</p>
          <a href={message.body} target="_blank" rel="noreferrer">
            <DownloadIcon color={theme.colors.text} size="18" />
          </a>
        </ContentWrapper>
      </DocumentWrapper>
      <Caption>
        <span dangerouslySetInnerHTML={{ __html: formatMessage(message.caption ?? '') }} />
      </Caption>
    </Container>
  );
}
