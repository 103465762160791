import { yupResolver } from '@hookform/resolvers/yup';
import { AddMacroModal } from 'components/AddMacroModal';
import { EditMacroModal } from 'components/EditMacroModal';
import { Checkbox } from 'components/Form/Checkbox';
import { Select } from 'components/Form/Select';
import { MacroManageableList } from 'components/MacroManageableList';
import { useMacros } from 'hooks/fetch/useMacros';
import { useDeleteMacro } from 'hooks/mutations/useDeleteMacro';
import { useContacts } from 'hooks/useContacts';
import { useMessages } from 'hooks/useMessages';
import { deleteMacro } from 'modules/macros';
import { MouseEvent, useEffect, useState } from 'react';
import { Edit2, Settings, Trash } from 'react-feather';
import { FieldValues, FormProvider, SubmitHandler, useForm } from "react-hook-form";
import Modal from 'react-modal';
import CreatableSelect from 'react-select/creatable';
import { api } from 'services/api';
import { useTheme } from 'styled-components';
import { Button } from 'styles/Button';
import { Form } from 'styles/Form';
import { FormRow } from 'styles/FormRow';
import { InputGroup } from 'styles/InputGroup';
import { MacroTextarea } from 'styles/MacroTextarea';
import { ModalTitle } from 'styles/Modal';
import { ChatMessage } from 'types/ChatMessage';
import { Macro } from 'types/Macro';
import { isEmpty } from 'utils/Misc';
import { handleRequestError } from 'utils/Request';
import { v4 as uuidv4 } from 'uuid';
import closeImg from '../../assets/close.svg';
import { ActionsButtons, Header, Option, OptionIcons } from './styles';
import { CustomMacroData, ManageMacroModalProps, SelectMacroData } from './types';
import { macroMessageValidator } from './validation';

const validationSchema = macroMessageValidator();

export function ManageMacroModal({ isOpen, onRequestClose, canSendMessage, isAnnotation }: ManageMacroModalProps) {
  const { data, isLoading } = useMacros();

  const [isNewMacroModalOpen, setIsNewMacroModalOpen] = useState(false);
  const [macroToAdd, setMacroToAdd] = useState('');

  const [isEditMacroModalOpen, setIsEditMacroModalOpen] = useState(false);
  const [macroToEdit, setMacroToEdit] = useState<Macro>({} as Macro);

  const [isMacroManageableListOpen, setIsMacroManageableListOpen] = useState(false);

  const theme = useTheme();

  const {
    messagesQuery: { query },
    selectedQuotedMessage,
    setSelectedQuotedMessage,
    setPreviewMessages,
    setIsRefetchingMessages,
  } = useMessages();

  const form = useForm<FieldValues>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      customMacro: '',
    },
  });

  const { handleSubmit, setValue, control, reset, formState } = form;

  useEffect(() => {
    if (!isOpen) return;

    if (!canSendMessage || isAnnotation) {
      setValue('annotation', true);
    } else {
      setValue('annotation', false);
    }
  }, [canSendMessage, setValue, isAnnotation, isOpen]);

  function handleOpenMacroManageableListModal() {
    setIsMacroManageableListOpen(true);
  }

  function handleCloseMacroManageableListModal() {
    setIsMacroManageableListOpen(false);
  }

  function handleOpenNewMacroModal() {
    setIsNewMacroModalOpen(true);
  }

  function handleCloseNewMacroModal() {
    setIsNewMacroModalOpen(false);
  }

  function handleOpenEditMacroModal(macro: Macro) {
    setMacroToEdit(macro);
    setIsEditMacroModalOpen(true);
  }

  function handleCloseEditMacroModal() {
    setMacroToEdit({} as Macro);
    setIsEditMacroModalOpen(false);
  }

  const { selectedContact } = useContacts();

  function handleRequestClose() {
    reset();
    onRequestClose();
  }

  async function handleSendMacroMessage({ customMacro, annotation }: CustomMacroData) {
    const quoted = selectedQuotedMessage;

    setSelectedQuotedMessage({} as ChatMessage);

    if (!customMacro) return;

    setPreviewMessages(prevMessages => {
      return [
        ...prevMessages,
        {
          id: uuidv4(),
          body: customMacro,
          contact_id: selectedContact.id,
          type: 'chat',
          quoted: !isEmpty(quoted) ? quoted : '',
          caption: '',
          is_forwarded: false,
          time: new Date().toString(),
          from_me: true,
          is_read: true,
          is_annotation: annotation,
          ack: 'SENT',
        } as ChatMessage
      ]
    });

    try {
      await api.post('/messages/send-text', {
        contactId: selectedContact.id,
        body: customMacro,
        quotedMsgId: !isEmpty(quoted) ? quoted.whatsapp_msg_id : null,
        isAnnotation: annotation,
      });

      setIsRefetchingMessages(true);
      await query.refetch();
      setPreviewMessages([]);
      setIsRefetchingMessages(false);
    } catch (error) {
      handleRequestError(error, 'Erro ao enviar mensagem. Por favor, tente novamente!');
    }

    reset();
    handleRequestClose();
  }

  function handleSelectMacro(newValue: unknown) {
    setValue('customMacro', (newValue as SelectMacroData).value.message ?? '', { shouldDirty: true });
  }

  function handleCreateMacroBySelect(inputValue: string) {
    setMacroToAdd(inputValue);
    handleOpenNewMacroModal();
  }

  const deleteMacroMutation = useDeleteMacro();

  async function handleDeleteMacro(e: MouseEvent, macro: Macro) {
    e.stopPropagation();

    await deleteMacro({
      mutation: deleteMacroMutation,
      currentTheme: theme,
      macro,
    });
  }

  function handleEditMacro(e: MouseEvent, macro: Macro) {
    e.stopPropagation();
    handleOpenEditMacroModal(macro);
  }

  function formatOptionLabel(option: SelectMacroData) {
    return (
      <Option>
        <div>{option.label}</div>
        {option.value.id && (
          <OptionIcons className="option-icons">
            <Edit2 onClick={(e) => handleEditMacro(e, option.value)} color={theme.colors.muted} size="14" />
            <Trash onClick={(e) => handleDeleteMacro(e, option.value)} color={theme.colors.danger} size="14" />
          </OptionIcons>
        )}
      </Option>
    )
  }

  return (
    <>
      <Modal
        isOpen={isOpen}
        onRequestClose={handleRequestClose}
        overlayClassName="react-modal-overlay"
        className="react-modal-content big"
      >
        <button
          type="button"
          onClick={handleRequestClose}
          className="react-modal-close"
        >
          <img src={closeImg} alt="Fechar modal" loading="lazy" />
        </button>

        <div>
          <Header>
            <ModalTitle noSpacing>Enviar mensagem de macro</ModalTitle>
            <ActionsButtons>
              <Button onClick={handleOpenMacroManageableListModal} accent small>
                <Settings color={theme.colors.text} size={14} />
              </Button>
              <Button
                onClick={handleOpenNewMacroModal}
                small
              >
                Novo macro
              </Button>
            </ActionsButtons>
          </Header>

          <Form onSubmit={handleSubmit(handleSendMacroMessage as SubmitHandler<FieldValues>)}>
            <FormProvider {...form}>
              <InputGroup>
                <Select
                  control={control}
                  name="selectMacro"
                  id="selectMacro"
                  label="Selecionar ou criar macros"
                  placeholder="Selecionar ou criar macro"
                  onSelect={handleSelectMacro}
                  fullwidth
                  config={{
                    noOptionsMessage: () => 'Nenhum macro disponível.',
                    options: data ? data.map(macro => ({ value: macro, label: macro.title })) : [],
                    closeMenuOnSelect: true,
                    onCreateOption: handleCreateMacroBySelect,
                    isDisabled: isLoading,
                    formatOptionLabel: formatOptionLabel,
                    formatCreateLabel: (inputValue: string) => `Criar macro "${inputValue}"`,
                    as: CreatableSelect,
                  }}
                >
                  {data && data.map((macro: Macro) => (
                    <option key={macro.id} value={macro.id}>
                      {macro.title}
                    </option>
                  ))}
                </Select>
              </InputGroup>
              <InputGroup>
                <MacroTextarea
                  control={control}
                  name="customMacro"
                  id="customMacro"
                  label="Mensagem"
                  placeholder="Mensagem do macro"
                  rows={6}
                  fullwidth
                />
              </InputGroup>
              <InputGroup>
                <Button
                  type="submit"
                  fullwidth
                  disabled={formState.isSubmitting}
                >
                  {formState.isSubmitting ? 'Carregando...' : 'Enviar'}
                </Button>
              </InputGroup>

              <FormRow>
                <InputGroup>
                  <Checkbox
                    control={control}
                    name="annotation"
                    label="Anotação"
                    id="macroAnnotation"
                    disabled={!canSendMessage}
                  />
                </InputGroup>
              </FormRow>
            </FormProvider>
          </Form>
        </div>
      </Modal>

      <AddMacroModal
        isOpen={isNewMacroModalOpen}
        onRequestClose={handleCloseNewMacroModal}
        macroToAdd={macroToAdd}
      />

      <EditMacroModal
        isOpen={isEditMacroModalOpen}
        onRequestClose={handleCloseEditMacroModal}
        macroToEdit={macroToEdit}
      />

      <MacroManageableList
        isOpen={isMacroManageableListOpen}
        onRequestClose={handleCloseMacroManageableListModal}
      />
    </>
  );
}
