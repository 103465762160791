import { Select } from "components/Form/Select";
import { useFormContext } from "react-hook-form";
import { InputGroup } from "styles/InputGroup";

export function SendSatisfaction() {
  const { control } = useFormContext();

  return (
    <InputGroup flexItem>
      <Select
        name="send_satisfaction"
        control={control}
        id="send_satisfaction"
        label="Enviar Pesquisa?"
        placeholder="Selecione"
        fullwidth
        config={{
          noOptionsMessage: () => 'Sem opções.',
          options: [
            { value: true, label: 'Sim' },
            { value: false, label: 'Não' },
          ],
          closeMenuOnSelect: true,
        }}
      >
        <option value="false">Não</option>
        <option value="true">Sim</option>
      </Select>
    </InputGroup>
  );
}