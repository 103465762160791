// import swal from "sweetalert";
import { DropdownListItem } from "components/Dropdown/styles";
import { LoadingSpinner } from "components/LoadingSpinner";
import { useCategories } from "hooks/fetch/useCategories";
import { Container, Loading } from "./styles";

export function CategoryList({ handleChangeUserCategory } : any) {
  const { data, isLoading } = useCategories();

  return (
    <Container>
      {isLoading && (
        <Loading>
          <LoadingSpinner size={15} thickness={1} />
        </Loading>
      )}

      {!isLoading && data && data.map((category) => (
        <DropdownListItem onClick={() => handleChangeUserCategory(category)} key={category.id} title={category.name}>
          <span>{category.name}</span>
        </DropdownListItem>
      ))}
    </Container>
  );
}
